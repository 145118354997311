<template>
  <the-wrapper :isSpinner="isSpinner">
    <call-tracking-description v-if="servicesData.Calltracking !== 2"></call-tracking-description>
    <call-tracking-connected v-else :rules="rules" :classes="classes"></call-tracking-connected>
  </the-wrapper>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { checkData } from '@/api/calltracking';
import { catchFunction } from '@/additional_helpers/helpers';

export default {
  data() {
    return {
      isSpinner: true,
      rules: []
    };
  },

  components: {
    TheWrapper,
    CallTrackingDescription: () => import('@/components/services/call_tracking/CallTrackingDescription.vue'),
    CallTrackingConnected: () => import('@/components/services/call_tracking/CallTrackingConnected.vue')
  },

  created() {
    if (this.servicesData.Calltracking === 2) {
      checkData()
        .then((result) => {
          this.rules = result;
          this.filterByProperty(this.rules);
          this.SHOW_LOADING(false);
        })
        .catch((e) => catchFunction(e));
    } else {
      this.SHOW_LOADING(false);
    }
  },

  computed: {
    ...mapGetters({ servicesData: 'connectedServices/servicesData' }),

    classes() {
      return this.rules.map((el, index) => {
        if (index - 1 >= 0 && el.CalltrackingNumber === this.rules[index - 1].CalltrackingNumber) {
          return 'dnone';
        }
        return '';
      });
    }
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' }),

    filterByProperty(tableData) {
      tableData.sort((curr, prev) => +curr.CalltrackingNumber - +prev.CalltrackingNumber);
      tableData.sort((curr, prev) => {
        if (+curr.CalltrackingNumber === +prev.CalltrackingNumber) {
          return prev.CreationTime - curr.CreationTime;
        }
        return +curr.CalltrackingNumber - +prev.CalltrackingNumber;
      });
    }
  }
};
</script>

<style></style>
