<template>
  <div>
    <div v-if="isLoading && isSpinner" :class="{ [$style.loading]: isLoading }">
      <base-spinner></base-spinner>
    </div>
    <b-row v-else-if="isAside" class="info-block_wrapper">
      <b-col lg="9">
        <slot></slot>
      </b-col>
      <b-col lg="3">
        <slot name="aside"></slot>
      </b-col>
    </b-row>
    <b-row v-else-if="!isAside && isFullWidth" class="info-block_wrapper">
      <b-col>
        <slot></slot>
      </b-col>
    </b-row>
    <b-row v-else class="info-block_wrapper_size">
      <b-col>
        <slot></slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseSpinner from '@/components/shared/BaseSpinner.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    isAside: {
      type: Boolean,
      require: false,
      default: false
    },
    isSpinner: {
      type: Boolean,
      require: false,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      require: false,
      default: false
    }
  },

  components: {
    BaseSpinner
  },

  computed: mapGetters({ isLoading: 'isLoading' })
};
</script>

<style module>
.loading {
  width: 100%;
  min-height: 70.77vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
