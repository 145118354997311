<template>
  <div>
    <b-spinner
      v-if="isBaseSpinner"
      :class="$style.baseSpinner"
      variant="danger"
      label="Loading..."
    ></b-spinner>
    <div v-if="isRequestSpinner" :class="$style.requestSpinner" variant="danger" label="Loading...">
      <b-spinner variant="danger" label="Loading..."></b-spinner>
      <div :class="$style.spinnerText">Получаем данные...</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBaseSpinner: {
      type: Boolean,
      require: false,
      default: true
    },
    isRequestSpinner: {
      type: Boolean,
      require: false,
      default: false
    }
  }
};
</script>

<style module>
.requestSpinner {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.spinnerText {
  padding-top: 1rem;
}

.baseSpinner {
  position: absolute;
  left: 42%;
  top: 40%;
}

@media (min-width: 576px) {
  .baseSpinner {
    left: 45%;
    top: 35%;
  }
}

@media (min-width: 768px) {
  .baseSpinner {
    left: 46.2%;
    top: 46.5%;
  }
}

@media (min-width: 992px) {
  .baseSpinner {
    left: 46.2%;
    top: 46.5%;
  }
}

@media (min-width: 1200px) {
  .baseSpinner {
    left: 46.2%;
    top: 46.5%;
  }
}
</style>
