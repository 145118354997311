import URL from '../basUrl';
import { getOptionsWithCookie } from './common';

export const checkData = async () => {
  const response = await fetch(URL.CALL_TRACKING, getOptionsWithCookie());
  return response.json();
};

export const editRule = async () => {
  const response = await fetch(URL.CALL_TRACKING, getOptionsWithCookie());
  return response;
};
